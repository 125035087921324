#xoriglist,
#xownwords,
#history {
  padding-right: 0px;
}

@media (min-width: 478px) {
  #xoriglist,
  #xownwords,
  #history {
    padding-left: 45px;
  }
}

.doneWord {
  background: #77cc77;
}

.activeWord {
}

.donechip {
  background: #99cc99;
  margin-bottom: 8px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 20px;
  padding: 8px;
  padding-left: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin-right: 20px;
}

.newchip {
  margin-bottom: 8px;
  margin-bottom: 8px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 20px;
  padding: 8px;
  padding-left: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin-right: 20px;
  min-height: 46px;
}

.pendingchip {
  background: #ffffee;
  margin-bottom: 8px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 20px;
  padding: 8px;
  padding-left: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin-right: 20px;
  min-height: 46px;
}

.activechip {
  background: #eebbbb;
  margin-bottom: 8px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 20px;
  padding: 8px;
  padding-left: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin-right: 20px;
  min-height: 46px;
}

@media (min-width: 478px) {
  .donechip,
  .newchip,
  .pendingchip,
  .activechip {
    width: calc(100% - 45px);
  }
}

.chipnumber {
  display: inline-block;
  padding-right: 10px;
  font-size: 13px;
  width: 35px;
}

.chipcross {
  padding-left: 30px;
}

.List {
  background: "white";
}

.pad30 {
  padding-left: "25px";
}

.xpos {
  padding-left: 3px;
  font-size: 14px;
  color: grey;
}

#xcards {
  background: white;
}

.hl1 {
  background: #ccccff;
  font-weight: bold;
}

#xtitle {
  font-size: 15px;
  flex: 1 1 auto;
}

.xtitle {
  flex: 1 1 auto;
}

#xtitle .xpos {
  padding-left: 3px;
  font-size: 14px;
  color: inherit;
}

.xtitlepos {
  padding-left: 10px;
}

.xentry {
}

@media (max-width: 478px) {
  #xloader {
    padding-right: 20px;
  }
}

.hlactivezdone {
  background: lightgrey;
  color: green;
  font-weight: bold;
}

.hlactivezactive {
  background: lightgrey;
  color: #551111;
  font-weight: bold;
}

.zdone {
  color: green;
  font-weight: bold;
}
.zactive {
  color: #551111;
  font-weight: bold;
}

.hlactive {
  background: lightgrey;
}

.cword {
  background: lightblue;
  font-weight: bold;
}

@keyframes gogrey {
  0% {
    color: black;
  }
  50% {
    color: lightgrey;
  }
  100% {
    color: black;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.anim {
  animation: gogrey 0.8s;
}
